import { createApp } from "vue/dist/vue.esm-bundler"
import "admin-lte"
import "bootstrap"
import "jquery"
import "@rails/ujs"
import Rails from "@rails/ujs"
//import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css' 
import { boton_desarrollo_app } from "./apps/boton_desarrollo"
import { registration_app } from "./apps/register"
import { company_app } from "./apps/company"
import { company_show_app } from "./apps/company_show"
import { program_show_app } from "./apps/program_show"
import { program_manager_app } from "./apps/program_manager"



const backend_data=window.backend_data

Rails.start();

const botondesarrollo_tag = document.querySelector("#botondesarrollo")
const regitration_tag = document.querySelector("#registration")
const company_tag = document.querySelector("#company")
const company_show_tag = document.querySelector("#company_show")
const program_show_tag = document.querySelector("#program_show")
const program_manager_tag = document.querySelector("#program_manager")




if (botondesarrollo_tag !== null) {const app=createApp(boton_desarrollo_app).mount(botondesarrollo_tag)}
if (regitration_tag !== null) {const app=createApp(registration_app).mount(regitration_tag)}
if (company_tag !== null) {const app=createApp(company_app).mount(company_tag)}
if (company_show_tag !== null) {const app=createApp(company_show_app).mount(company_show_tag)}
if (program_show_tag !== null) {const app=createApp(program_show_app).mount(program_show_tag)}
if (program_manager_tag !== null) {const app=createApp(program_manager_app).mount(program_manager_tag)}



export default {
    plugins: [ /*
      inject({ 
          $: 'jquery',
          "window.jQuery": "jquery",
          jQuery: 'jquery',

      }),
      RubyPlugin()
    */]
  };

